@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
* {
    font-family: 'Open Sans', sans-serif;
}
body {height: 500px;}
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80%;
}


/* @import url('https://fonts.googleapis.com/css2?family=Noto+Emoji&family=Open+Sans:wght@300&display=swap');

.emoji {
    font-family: 'Noto Emoji', sans-serif;
} */


/* nice gradient! */
/* background-color: #7f5a83;
background-image: linear-gradient(315deg, #7f5a83 0%, #0d324d 74%); */
